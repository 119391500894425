export default {
  methods: {
    convertSecondToTime: function(sec) {
      // 桁数が1桁だったら先頭に0を加えて2桁に調整する
      function set2fig(num) {
        return ( num < 10 ) ? ( "0" + num ) : num;
      }

      const timeH = Math.floor(sec % (24 * 60 * 60) / (60 * 60));
      const timeM = Math.floor(sec % (24 * 60 * 60) % (60 * 60) / 60);
      const timeS = Math.floor(sec % (24 * 60 * 60) % (60 * 60) % 60 );
      return set2fig(timeH) + ':' + set2fig(timeM) + ':' + set2fig(timeS);
    },
    convertTimeToSecond: function(time) {
      const [hour, minute, sec] = time.split(':');
      return Number(hour) * 3600 + Number(minute) * 60 + Number(sec);
    },
    checkPopoverEnabled(insights) {
      if (!insights.length) return false;

      return insights.some(insight => {
        return insight.popoverTarget && insight.popoverTarget.length
      });
    },
  },
}
