<template>
  <InsightControllArea
    :insights="insights"
    :currentSeconds="currentSeconds"
    @addInsight="addInsight($event)"
    @updateStartInputMemoSeconds="setStartInputMemoSeconds($event)"
  />
  <div class='mt-3'>
    現在の再生速度: {{ currentVideoSpeed.toFixed(1) }} / 現在の発見点を入力開始したときの時間: {{ startInputMemoSeconds }}
  </div>
  <InsightDisplayArea
    :insights="insights"
    :currentSeconds="currentSeconds"
    @jumpTo="jumpTo($event)"
  />
  <div class="d-flex justify-content-end">
    <Button @click="restoreInsight" v-show= "CanRestoreInsight()">
      復元
    </Button>
    <div v-show="insights.length > 0">
      <Button
        class="mx-3"
        :buttonStyle="'light'"
        data-bs-toggle='modal'
        data-bs-target='#deleteInsightsModal'
        :disabled='!insights.length || checkPopoverEnabled(insights)'
      >
        <fa icon='trash' />
        データの削除
      </Button>
      <DeleteInsightsModal
        @deleteInsights="deleteInsights"
        tabindex='-1'
      />

      <Button
        class='dropdown-toggle'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-expanded='false'
        :disabled='!insights.length || checkPopoverEnabled(insights)'
      >
        <fa icon='download' />
        データを出力する
      </Button>
      <ul
        class='dropdown-menu'
        aria-labelledby='dropdownMenuButton'
      >
        <li>
          <a
            class='dropdown-item'
            href='#'
            @click="downloadInsights({ format: 'all' })"
          >
            全てのデータ
          </a>
        </li>
        <li>
          <a
            class='dropdown-item'
            href='#'
            @click="downloadInsights({ format: 'onlyStartTimeAndMemo' })"
          >
            発見点エクセル用（セル結合/POPlayer取込不可）
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import InsightControllArea from './InsightControllArea.vue';
  import InsightDisplayArea from './InsightDisplayArea.vue';
  import Button from '../components/Button.vue';
  import DeleteInsightsModal from '../components/DeleteInsightsModal.vue';
  import Encoding from 'encoding-japanese';
  import { DateTime } from 'luxon';
  import GlobalMethods from '../mixins/globalMethods.js'

  export default {
  mixins: [ GlobalMethods ],
  props: {
      currentSeconds: {
        type: Number,
        required: true,
        default: 0,
      },
      currentVideoSpeed: {
        type: Number,
        required: true,
        default: () => {
          return 1.0;
        }
      },
    },
    emits: ['jumpTo'],
    components: {
      InsightControllArea,
      InsightDisplayArea,
      Button,
      DeleteInsightsModal
    },
    data() {
      return {
        insights: [],
        startInputMemoSeconds: undefined, // 現在の発見点を入力開始したときの時刻
        insightsSaved: false
      }
    },
    created() {
      window.addEventListener('beforeunload', this.confirmSave);
    },
    unmounted() {
      window.removeEventListener('beforeunload', this.confirmSave);
    },
    methods: {
      CanRestoreInsight: function(){
        return this.insights.length == 0 && localStorage.getItem('poplayerInsights') && JSON.parse(localStorage.getItem('poplayerInsights')).length
      },
      restoreInsight: function() {
        this.insights = JSON.parse(localStorage.getItem('poplayerInsights'));
      },
      addInsight: function(insights) {
        this.insights = insights;
      },
      setStartInputMemoSeconds: function(seconds) {
        this.startInputMemoSeconds = seconds;
      },
      jumpTo: function(seconds) {
        this.$emit('jumpTo', seconds);
      },
      deleteInsights: function() {
        this.insights.splice(0);
      },
      confirmSave: function(e) {
        if (this.insights.length && this.insightsSaved === false) {
          e.preventDefault();
          e.returnValue = '発見点が破棄されますが、よろしいですか？'; //表示はされないが、設定する必要があるため
        }
      },
      downloadInsights: function({ format }) {
        const self = this;

        let tsvString = '';
        if (format === 'all') {
          tsvString = '開始時間\t終了時間\tメモ\t補助メモ\r\n';
          self.insights.forEach(function(insight) {
            tsvString += `${self.fixTimeFormat(insight.startTime)}\t${self.fixTimeFormat(insight.endTime)}\t${insight.memo}${insight.memo && "\t" + insight.subMemo}\r\n`;
          });
        } else if (format === 'onlyStartTimeAndMemo') {
          tsvString = '開始時間・メモ\t補助メモ\r\n';
          self.insights.forEach(function(insight) {
            tsvString += `${self.fixTimeFormat(insight.startTime)} ${insight.memo}${insight.subMemo && "\t" + insight.subMemo}\r\n`;
          });
        }
        const codes = Encoding.stringToCode(tsvString);
        const sjisCodes = Encoding.convert(codes, 'SJIS', 'UNICODE');
        const uInt8Data = new Uint8Array(sjisCodes);
        const blob = new Blob([uInt8Data], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `download_${DateTime.now().toFormat('yyyyMMddHHmmss')}.txt`;
        link.click();
        window.URL.revokeObjectURL(url);

        self.insightsSaved = true;
      },
      fixTimeFormat: function(time) {
        if (!time) return ''
        let hmsArray = time.split(':').map((time) => Number(time) < 10 ? `0${Number(time)}` : time)
        if (hmsArray.length < 3) {
          hmsArray.unshift('00')
        }
        return hmsArray.join(':')
      },
    },
    watch: {
      insights: {
        handler() {
          this.insightsSaved = false;
        },
        deep: true
      }
    }
  }
</script>
