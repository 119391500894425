<template>
  <div class='title'>
    {{ title }}
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
    }
  }
</script>

<style scoped>
  .title {
    background-color: #e6e5e5;
    border-radius: 6px;
    padding: 8px;
    width: calc(100% - 16px);
    margin: 0px 8px 24px 8px;
    color: gray;
  }
</style>
