<template>
  <div class='border bg-light p-3 small'>
    <div
      v-for="(keyControll, index) in this.keyControllList"
      v-bind:key="index"
    >
      <div class='row'>
        <div class='col-7'>{{ keyControll['key'] }}</div>
        <div class='col-1'>:</div>
        <div class='col-4'>{{ keyControll['description'] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isWidows: (window.navigator.userAgent.toLowerCase().indexOf('windows nt') !== -1),
        keyControllList: [
          { key: 'mod + j', description: '再生・一時停止' },
          { key: 'mod + h（mod + ←）', description: '5秒巻き戻し' },
          { key: 'mod + k（mod + →）', description: '5秒早送り' },
          { key: 'mod + g', description: '30秒巻き戻し' },
          { key: 'mod + l', description: '30秒早送り' },
          { key: 'mod + shift + ,', description: '再生速度を遅く' },
          { key: 'mod + shift + .', description: '再生速度を速く' },
          { key: 'mod + Enter', description: '発見点を追加' },
        ],
      }
    },
    created() {
      const self = this;
      this.keyControllList.forEach((keyControll, index) => {
        const keyName = (function(){
          if (self.isWidows) {
            return 'Ctrl';
          } else {
            return 'Command';
          }
        })();
        self.keyControllList[index].key = keyControll.key.replace(/mod/g, keyName);
      });
    },
  }
</script>
