<template>
  <div class='video mb-3'>
    <vue-plyr
      ref='plyr'
      :options="this.options"
      >
      <video
        v-bind:src="this.videoURL"
        @timeupdate="onTimeUpdateListener"
        @ratechange="onRateChangeListener"
        id='targetVideo'
        class='mousetrap'
        v-bind:style="{width: 'calc(' + this.videoWidth / 2  + 'vw - 80px)'}"
        controls
        autoplay
        />
    </vue-plyr>
  </div>
</template>

<script>
  import { watchEffect } from 'vue';

  let video_speed_range = [...Array(21)].map((_, i) => (i + 5) / 10);
  video_speed_range.push(3.0, 4.0);
  const VIDEO_SPEED_RANGE = video_speed_range;


  export default {
    props: {
      videoURL:  {
        type: String,
        default: '',
      },
      videoWidth: {
        type: Number,
        default: 100,
      },
      currentSeconds: {
        type: Number,
        default: 0,
      }
    },
    emits: [
      'videoTimeUpdate',
      'videoSpeadChange'
    ],
    data() {
      return {
        currentPlayRate: 1,
        videoSpeedIndex: VIDEO_SPEED_RANGE.indexOf(1.0),
        options: {
          title: 'MonitorVideo',
          invertTime: false,
          hideControls: false,
          storage: { enabled: false, key: 'plyr' },
          keyboard: { focused: false, global: false },
          speed: { selected: 1, options: VIDEO_SPEED_RANGE },
          seekTime: 5,
          controls: [
            'play-large',
            'play',
            'rewind',
            'fast-forward',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
            'captions',
            'settings',
            'pip',
            'airplay',
            'fullscreen'
          ]
        },
      }
    },
    computed: {
      player() {
        return this.$refs.plyr.player;
      }
    },
    mounted: function() {
      let self = this;
      watchEffect(() => self.watchProps());

      // ショートカットキーの設定
      self.$mousetrap.bind('mod+j', function() {
        self.player.paused ? self.player.play() : self.player.pause();
        return false;
      });

      self.$mousetrap.bind('mod+h', function() {
        self.player.rewind(5);
        return false;
      });

      self.$mousetrap.bind('mod+left', function() {
        self.player.rewind(5);
        return false;
      });

      self.$mousetrap.bind('mod+k', function() {
        self.player.forward(5);
        return false;
      });

      self.$mousetrap.bind('mod+right', function() {
        self.player.forward(5);
        return false;
      });

      self.$mousetrap.bind('mod+g', function() {
        self.player.rewind(30);
        return false;
      });

      self.$mousetrap.bind('mod+l', function() {
        self.player.forward(30);
        return false;
      });

      self.$mousetrap.bind('mod+shift+,', function() {
        self.speedDown();
        return false;
      });

      self.$mousetrap.bind('mod+shift+.', function() {
        self.speedUp();
        return false;
      });
    },
    beforeUnmount: function() {
      const self = this;
      self.videoElm.removeEventListener('ratechange', this.onRateChangeListener);
      self.videoElm.removeEventListener('timeupdate', this.onTimeUpdateListener);
    },
    methods: {
      watchProps: function() {
        let self = this;
        // 指定の秒数にジャンプする
        self.videoElm = document.querySelector('video');
        if (self.videoElm) {
          self.videoElm.currentTime = self.currentSeconds;
        }
      },
      speedUp: function() {
        if (VIDEO_SPEED_RANGE.length != this.videoSpeedIndex + 1) this.videoSpeedIndex++;
        this.videoElm.playbackRate = VIDEO_SPEED_RANGE[this.videoSpeedIndex];
      },
      speedDown: function() {
        if (this.videoSpeedIndex != 0) this.videoSpeedIndex--;
        this.videoElm.playbackRate = VIDEO_SPEED_RANGE[this.videoSpeedIndex];
      },
      onTimeUpdateListener: function() {
        this.$emit('videoTimeUpdate', this.videoElm.currentTime);
      },
      onRateChangeListener: function() {
        this.$emit('videoSpeedChange', this.videoElm.playbackRate);
      }
    }
  }
</script>

<style scoped>
  .video {
    height: auto;
    text-align: center;
  }
</style>
