<template>
  <div id='setting-time' class='row m-1'>
    <TitleBar title='2. 時間の設定' />
    <div class='col-sm vh-100 sticky-top'>
      <div class='mb-5'>
        <div class='step-title'>
          [Step1] 動画ファイルを選択
        </div>
        <MonitorVideo
          :videoURL="playVideoUrl"
          :currentSeconds="currentSeconds"
        />
        <div class='step' v-show="showInputVideoForm">
          <InputVideo
            :videoURL="formInputedVideoUrl"
            @changeVideo="setFormInputedVideoUrl"
          />
        </div>
      </div>
      <div class='step'>
        <div class='step-title'>
          [Step2] 発見点リストの作成
        </div>
        <InputData
          v-model:inputText="insightDataFromExcel"
        />
        <div class='pull-right'>
          <Button
            @click="generateInsightList"
            :disabled="checkPopoverEnabled(insights)"
          >
            発見点リストを生成
          </Button>
        </div>
      </div>
    </div>
    <div class='col-sm'>
      <div class='mb-5'>
        <div class='step-title'>
          [Step3] 発見点別に開始点（S）と終了点（E）をセットする
        </div>
        <KeyControllGuideForTimeSetting />
        <div class='step'>
          <InsightList
            :insights="insights"
            :video="video"
            @jumpTo="jumpTo($event)"
          />
        </div>
      </div>
      <div class='mb-5'>
        <div class='step-title'>
          [Step4] 開始時間・終了時間リストを出力
        </div>
        <OutputData
          :insights="insights"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../components/Button.vue';
  import InputData from '../components/InputData.vue';
  import InputVideo from '../components/InputVideo.vue';
  import InsightList from '../components/InsightList.vue';
  import KeyControllGuideForTimeSetting from '../components/KeyControllGuideForTimeSetting.vue';
  import MonitorVideo from '../components/MonitorVideo.vue';
  import TitleBar from '../components/TitleBar.vue';
  import OutputData from '../components/OutputData.vue';
  import GlobalMethods from '../mixins/globalMethods.js';

  export default {
    mixins: [GlobalMethods],
    components: {
      Button,
      InputData,
      InputVideo,
      InsightList,
      KeyControllGuideForTimeSetting,
      MonitorVideo,
      TitleBar,
      OutputData,
    },
    data(){
      return {
        insightDataFromExcel: '',
        video: Object,
        currentSeconds: 0,
        insights: [],
        formInputedVideoUrl: null
      }
    },
    props: {
      showInputVideoForm: {
        type: Boolean,
        required: false,
        default: false
      },
      videoUrl: {
        type: String,
        required: false,
        default: null
      }
    },
    computed: {
      playVideoUrl() {
        if (this.videoUrl) return this.videoUrl;
        return this.formInputedVideoUrl;
      }
    },
    mounted() {
      this.video = document.querySelector('video');
    },
    methods: {
      jumpTo: function(timeString) {
        this.currentSeconds = this.stringToTime(timeString);
        this.video.currentTime = this.currentSeconds
        this.video.play()
      },
      stringToTime: function(timeString) {
        return +timeString.split(':').reduce((prev, curr) => (60 * prev) + +curr);
      },
      setFormInputedVideoUrl: function(videoURL) {
        this.formInputedVideoUrl = videoURL;
      },
      generateInsightList: function() {
        this.insights = [];

        const rows = this.insightDataFromExcel.split(/\n/);
        rows.forEach((row) => {
          if (!/\t/.test(row)) return

          const insight = {}
          // セルごとに区切る
          const cellArr = row.split(/\t/);
          let newCellArr = cellArr.map(
            // 先頭にダブルクオーテーションが存在する場合は削除
            function(cell){
              if (cell[0] == '"') { cell = cell.slice(1) }
              return cell;
            }
          )
          insight.text = newCellArr[0]
          const regexpTime = /^[0-5][0-9](:|：)[0-5][0-9](:|：)[0-5][0-9]/
          if (regexpTime.test(newCellArr[1])) {
            insight.startTime = newCellArr[1].slice(0,8).replace(/：/g, ':')
            insight.description = newCellArr[1].slice(8)
          } else {
            insight.startTime = ''
            insight.description = newCellArr[1]
          }
          this.insights.push(insight)
        })
      }
    }
  };
</script>


<style scoped>
  .step {
    margin: 16px 0px;
  }

  .step-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;
  }
</style>
