<template>
  <div
    class='mb-3 insight-table-area'
    ref="insight_table_area"
  >
    <InsightTable
      :insights="insights"
      :currentSeconds="currentSeconds"
      @jumpTo="jumpTo($event)"
      />
  </div>
</template>

<script>
import InsightTable from './InsightTable.vue';

  export default {
    components: {
      InsightTable,
    },
    props: {
      insights: {
        type: Array,
        required: true,
        default: () => {
          return [] // データのデフォルト値
        },
      },
      currentSeconds: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    mounted: function () {
      // 画面サイズ変更を検知
      window.addEventListener('resize', this.setDynamicStyle);
      this.setDynamicStyle();
    },
    beforeUnmount: function () {
      window.removeEventListener('resize', this.setDynamicStyle);
    },
    methods: {
      jumpTo: function(time) {
        this.$emit('jumpTo', time);
      },
      setDynamicStyle: function() {
        // テーブルの高さを変更する
        const elm = this.$refs.insight_table_area;
        const positionY = elm.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        elm.style.maxHeight = (windowHeight - (positionY + 80)) + 'px';
      },
    }
  };
</script>

<style scoped>
  .insight-table-area {
    overflow: scroll;
    margin-top: 16px;
  }
  .insight-table-area::-webkit-scrollbar{
    display: none;
  }
</style>
