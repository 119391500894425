<template>
  <nav class='navbar bg-dark text-white'>
      <div class='d-flex justify-content-start align-items-center mx-lg-4'>
        <div class='navbar-brand font-weight-bold'>
          <fa icon='photo-video' />
          POPlayer
          <small>v1.4 β</small>
        </div>
      </div>
  </nav>

  <div class="mx-3 mt-3">
    <a
      href='#'
      @click="this.displayPlayer"
    >
      発見点の追加
    </a>
    <a
      href='#'
      @click="this.displaySettingTime"
    >
      時間の設定
    </a>
  </div>


</template>

<script>
  export default {
    methods: {
      displayPlayer: function(){
        this.$router.push(`/${location.search}`)
      },
      displaySettingTime: function(){
        this.$router.push(`/setting-time/${location.search}`)
      },
    },
  }
</script>

<style scoped>
  a {
    margin-right: 10px;
    font-size: 14px;
  }
</style>
