import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import jQuery from 'jquery';
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require('jquery');

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHistory, fas } from '@fortawesome/free-solid-svg-icons';
import { faPhotoVideo, faTrash, faPlus, faSort, faTable, faCaretDown, faQuestionCircle, faPencilAlt, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  fas,
  faPhotoVideo,
  faTrash,
  faPlus,
  faSort,
  faTable,
  faCaretDown,
  faQuestionCircle,
  faPencilAlt,
  faHistory,
  faExclamationCircle,
);

import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import VueMousetrapPlugin from 'vue-mousetrap';
import VueSweetalert2 from 'vue-sweetalert2';

const plyrOptions = {
  title: 'Example Video',
  invertTime: false,
  hideControls: false,
  storage: {
    enabled: false,
    key: 'plyr'
  },
  keyboard: {
    focused: false,
    global: false,
  },
  speed: {
    selected: 1,
    options: [...Array(21)].map((_, i) => (i + 5) / 10).push(3.0, 4.0)
  },
  seekTime: 5,
  controls: [
    'play-large',
    'play',
    'rewind',
    'fast-forward',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'captions',
    'settings',
    'pip',
    'airplay',
    'fullscreen',
  ]
};

createApp(App)
  .use(router) // VueRouterの有効化
  .component("fa", FontAwesomeIcon)
  .use(VuePlyr, { plyr: plyrOptions })
  .use(VueMousetrapPlugin)
  .use(VueSweetalert2)
  .mount('#app');
