<template>
  <Header />
  <div :class="`alert alert-${alertType} mx-4 mt-3`" style="margin-bottom: 0" v-if="message">
    {{ message }}
  </div>
  <div id="content" class='p-3' v-if="authorized">
    <router-view :showInputVideoForm="!videoUrl" :videoUrl="videoUrl"/>
  </div>
</template>

<script>
  import Header from './components/Header.vue';

  export default {
    data() {
      return {
        authorized: false,
        message: '',
        alertType: 'info',
        videoUrl: null,
        env: null,
        videoObjectKey: null,
      }
    },
    components: {
      Header,
    },
    methods : {
      setMeta(route){
        // タイトルを設定
        if(route.meta.title){
          let setTitle = route.meta.title;
          document.title = setTitle;
        }
      },
      getEnv() {
        const videoUrlString = new URL(window.location.href).searchParams.get("videoUrl");

        if (videoUrlString) {
          this.env = videoUrlString.split("popinsight-")[1].split(".s3")[0];
        } else {
          // POPLayerの利用を許可する環境変数文字列
          // production  : 本番
          // training    : 選考用JP
          // staging     : ステージング
          // development : 開発環境
          const allowedEnv = ['production', 'training', 'staging', 'development'];

          const envString = new URL(window.location.href).searchParams.get('env');

          if( allowedEnv.includes(envString) ) {
            this.env = envString;
          }
        }
      },
      getVideoObjectKey() {
        const videoUrlString = new URL(window.location.href).searchParams.get("videoUrl");
        if (videoUrlString){
          this.videoObjectKey = videoUrlString.split("amazonaws.com/")[1].split("?accessTime=")[0];

        } else {
          this.videoObjectKey = new URL(window.location.href).searchParams.get('video');
        }
      },
      generateVideoUrl() {
        if (!this.env || !this.videoObjectKey) {
          this.videoUrl = '';
          return;
        }

        if (this.env != "development") {
          this.videoUrl = `https://popinsight-${this.env}.s3-ap-northeast-1.amazonaws.com/${this.videoObjectKey}?accessTime=${Date.now()}`;
        } else {
          this.videoUrl = `http://pop-minio.me:9000/popinsight-development/${this.videoObjectKey}?accessTime=${Date.now()}`
        }
      },
      checkAuthorization() {
        const PRODUCTION_DOMAIN_NAME = 'https://usertesting.jp';
        const TRAINING_DOMAIN_NAME = 'https://training.usertesting.jp';
        const STAGING_DOMAIN_NAME = 'https://staging.usertesting.jp';
        const DEVELOPMENT_DOMAIN_NAME = 'http://jp-dev.me:3000';

        const self = this;
        let apiDomainName = '';

        if (self.env === 'production')  apiDomainName = PRODUCTION_DOMAIN_NAME;
        else if (self.env === 'training') apiDomainName = TRAINING_DOMAIN_NAME;
        else if (self.env === 'staging') apiDomainName = STAGING_DOMAIN_NAME;
        else if (self.env === 'development')  apiDomainName = DEVELOPMENT_DOMAIN_NAME;
        else {
          self.message = 'エラーが発生しました。開発チームにお問い合わせください。';
          self.alertType = 'danger';
          return;
        }

        const apiUrl = `${apiDomainName}/api/authorization/check_permission_for_poplayer?object_key=${self.videoObjectKey}`;

        fetch(apiUrl, {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'From-Poplayer': 'true'
          }
        }).then(function(data) {
          return data.json();
        }).then(function(json) {
          if (json.status === 200) {
            self.authorized = true;
          } else if (json.status === 400) {
            self.message = '対象の動画が存在しません。';
            self.alertType = 'danger';
          } else if (json.status === 401) {
            self.message = "JPへログインしてください。2秒後にログイン画面へリダイレクトします。";
            self.alertType = 'warning';
            setTimeout(() => {
              location.href =`${apiDomainName}/?redirect_to=${encodeURI(location.href)}`;
            }, 2000);
          } else if (json.status === 403) {
            self.message = "権限がないため動画を読み込めません。";
            self.alertType = 'danger';
          }
        }).catch(function() {
          self.message = 'エラーが発生しました。開発チームにお問い合わせください。';
          self.alertType = 'danger';
        });
      },
      appendNoIndex() {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content','noindex');
        document.getElementsByTagName('head')[0].appendChild(meta);
      },
    },
    created() {
      this.getEnv();
      this.getVideoObjectKey();
      this.generateVideoUrl();

      if (this.videoUrl) {
        this.checkAuthorization();
      } else {
        this.authorized = true
      }
    },
    mounted() {
      this.appendNoIndex();

      let route = this.$route;
      this.setMeta(route);
    },
    watch: {
      '$route' (route) {
        this.setMeta(route);
      }
    }
  }
</script>
