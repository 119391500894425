<template>
  <div class='border bg-light p-3 small'>
    <div v-for="n in keyControllList.length / 2" :key="n">
      <div class='row'>
        <template
          v-for="(keyControll, index) in keyControllList.slice((n-1)*2, (n-1)*2 + 2)"
          v-bind:key="(n-1)*2+index"
        >
          <div class='col-3'>{{ keyControll['key'] }}</div>
          <div class='col-3'>: {{ keyControll['description'] }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isWidows: (window.navigator.userAgent.toLowerCase().indexOf('windows nt') !== -1),
        keyControllList: [
          { key: 'mod + j', description: '再生・一時停止' },
          { key: '', description: '' },
          { key: 'mod + h（mod + ←）', description: '5秒巻き戻し' },
          { key: 'mod + k（mod + →）', description: '5秒早送り' },
          { key: 'mod + shift + ,', description: '再生速度を遅く' },
          { key: 'mod + shift + .', description: '再生速度を速く' },
          { key: 'mod + s', description: '開始地点の設定' },
          { key: 'mod + e', description: '終了地点の設定' },
          { key: 'mod + ↑', description: '前のポイントへ' },
          { key: 'mod + ↓', description: '次のポイントへ' },
        ],
      }
    },
    created() {
      const self = this;
      this.keyControllList.forEach((keyControll, index) => {
        const keyName = (function(){
          if (self.isWidows) {
            return 'Ctrl';
          } else {
            return 'Command';
          }
        })();
        self.keyControllList[index].key = keyControll.key.replace(/mod/g, keyName);
      });
    },
  }
</script>
