<template>
  <div class='input-group ml-5 mb-3 video-width'>
    <span class='input-group-text label'>
      動画サイズ
    </span>
    <input
      id='videoWidthInput'
      v-model="videoWidth"
      @input="videoWidthInput"
      type='number'
      class='form-control'
    >
  </div>
</template>

<script>
  export default {
    name: 'InputVideoWidth',
    emits: ['changeVideoWidth'],
    data() {
      return {
        videoWidth: 100,
      }
    },
    methods: {
      videoWidthInput: function(e) {
        const self = this;
        self.$emit('changeVideoWidth', e.target.value);
      }
    }
  };
</script>

<style scoped>
  .video-width {
    width: 300px;
  }

  .label {
    position: relative;
    left: 1px;
  }
</style>
