<template>
  <div class="input-group mb-3">
    <input
      id='videoSelector'
      type='file'
      class="form-control"
      placeholder="動画を選択..."
      aria-label="動画を選択"
      aria-describedby="basic-addon2"
      accept='video/*'
      @change="fileSelected"
    >
  </div>
</template>

<script>
  export default {
    props: {
      videoURL: String,
    },
    emits: ['changeVideo'],
    methods: {
      fileSelected: function() {
        this.$emit('changeVideo', URL.createObjectURL(document.querySelector('#videoSelector').files[0]));
      },
    }
  };
</script>
