<template>
  <div id='inputCsvConfirmModal' class='modal'>
    <div class='modal-dialog modal-dialog-centered'>
      <div class='modal-content'>
        <div class='modal-body'>
          <div class="d-flex flex-row-reverse">
            <button
              id="closeModalBtn"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="閉じる"
            />
          </div>
          <div class="d-flex justify-content-center m-1">
            現在、POPlayerに入力中のデータに追加する形でデータを取り込みます。よろしいですか？
          </div>
          <div class="d-flex justify-content-center m-4">
            <Button
              class="mx-2"
              data-bs-dismiss="modal"
              :width="'170px'"
              @click="$emit('addCsvInsights')">
              データを取り込む
            </Button>
            <Button
              class="mx-2"
              data-bs-dismiss="modal"
              :width="'150px'"
              :buttonStyle="'danger'">
              元の画面に戻る
            </Button>
          </div>
          <div class="d-flex justify-content-center m-1" style="font-size: 0.9rem">
            ※ローカルに保存したデータのみPOPlayer上に表示したい場合、現在入力中のデータを削除してから、再度データの取り込みをお試しください。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from './Button.vue';

  export default {
    emits: ['addCsvInsights'],
    components: {
      Button
    }
  }
</script>
