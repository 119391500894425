<template>
  <div>
    <table>
      <tbody>
        <div
          v-for="(insight, index) in localInsights"
          :key="index"
        >
          <tr
            @click="clickInsight(index)"
            :class="[selectedInsightIndex == index && 'selected']"
          >
            <div class='text-area'>
              <td class='col-number'>
                {{ index + 1 }}
              </td>
              <td class='col-text'>
                <a
                  href='#'
                  @click="emitJumpTo(insight.startTime)"
                >
                  {{ insight.text }}
                </a>
              </td>
              <td class='col-description'>
                {{ insight.description }}
              </td>
            </div>
            <div class='time-area'>
              <td class='col-time'>
                <label>S:</label>
                <Popover
                  placement="bottom-start"
                  content='有効な時間を"00:00:00"形式で入力してください'
                  :show="insight.popoverTarget === 'startTime'"
                  :type="'error'"
                >
                  <input
                    class='inputTime'
                    v-model="localInsights[index].startTime"
                    placeholder='00:00:00'
                    @blur="validateTime({ target: 'startTime', index: index }, $event)"
                    @keyup.enter="$event.target.blur()"
                  />
                </Popover>
              </td>
              <td class='col-time'>
                <label>E:</label>
                <Popover
                  placement="bottom-start"
                  content='有効な時間を"00:00:00"形式で入力してください'
                  :show="insight.popoverTarget === 'endTime'"
                  :type="'error'"
                >
                  <input
                    class='inputTime'
                    v-model="localInsights[index].endTime"
                    placeholder='00:00:00'
                    @blur="validateTime({ target: 'endTime', index: index }, $event)"
                    @keyup.enter="$event.target.blur()"
                  />
                </Popover>
              </td>
            </div>
          </tr>
        </div>
      </tbody>
    </table>
  </div>
</template>

<script>
import GlobalMethods from '../mixins/globalMethods.js'
import Popover from '../components/Popover.vue';

export default {
  components: {
    Popover,
  },
  props: {
    insights: Object,
    video: Object,
  },
  emits: [
    'jumpTo',
    'addInsights',
    'changeVideo'
  ],
  mixins: [GlobalMethods],
  data() {
    return {
      selectedInsightIndex: 0
    }
  },
  computed: {
    localInsights: {
      get: function() {
        return this.insights;
      },
      set: function(insight) {
        this.$emit('addInsights', insight);
      },
    },
    localVideo: {
      get: function() {
        return this.video;
      },
      set: function(video) {
        this.$emit('changeVideo', video);
      },
    },
  },
  mounted() {
    let self = this;
    // ショートカットキーを登録
    self.$mousetrap.bind('mod+s', self.setStartTime); // 開始地点の設定
    self.$mousetrap.bind('mod+e', self.setEndTime); // 終了地点の設定
    self.$mousetrap.bind('mod+down', self.moveNextInsight); // 次のポイントへ
    self.$mousetrap.bind('mod+up', self.moveLastInsight); // 前のポイントへ
  },
  methods: {
    validateTime: function({ target, index }, e) {
      if (!e.target.value) {
        this.localInsights[index].popoverTarget = ''
        return;
      }

      const TIME_PATTERN = '^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';
      const regex = new RegExp(TIME_PATTERN);
      const result = regex.test(e.target.value);
      if (!result) {
        this.localInsights[index].popoverTarget = target
        e.target.focus();
        return;
      }
      this.localInsights[index].popoverTarget = ''
      this.localInsights[index].editMode = false;
    },
    emitJumpTo: function(timeString) {
      this.$emit('jumpTo', timeString);
    },
    moveNextInsight: function() {
      if (this.insights.length > this.selectedInsightIndex) {
        this.selectedInsightIndex++;
        this.$emit('jumpTo', this.localInsights[this.selectedInsightIndex].startTime);
      }
      return false;
    },
    moveLastInsight: function() {
      if (this.selectedInsightIndex > 0) {
        this.selectedInsightIndex--;
        this.$emit('jumpTo', this.localInsights[this.selectedInsightIndex].startTime);
      }
      return false;
    },
    setStartTime: function() {
      this.localInsights[this.selectedInsightIndex].startTime = this.convertSecondToTime(this.localVideo.currentTime);
      // デフォルトのキーバインドを無効化
      return false;
    },
    setEndTime: function() {
      this.localInsights[this.selectedInsightIndex].endTime = this.convertSecondToTime(this.localVideo.currentTime);
    },
    clickInsight: function(insightIndex) {
      this.selectedInsightIndex = insightIndex;
    },
  }
}
</script>

<style scoped>
  table {
    width: 100%;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 4px;
    max-width: 47vw;
    font-size: 14px;
  }

  tr > .text-area {
    display: flex;
    width: calc(100% - 220px);
  }

  tr > .time-area {
    display: flex;
    justify-content: flex-end;
    width: 220px;
  }

  .col-text, .col-description {
    display: flex;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow:ellipsis;
    margin-right: 4px;
  }

  .col-text {
    width: 30%;
  }

  .col-description {
    width: 70%;
  }

  .col-number {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  .col-text a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .col-time {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  .col-time input::placeholder {
    color: lightgray;
  }

  label {
    margin-right: 4px;
  }

  .inputTime {
    max-width: 80px;
  }

  .selected {
    background-color: bisque;
  }
</style>
