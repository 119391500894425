<template>
    <div>
      <input
        id='insightText'
        class='form-control rounded-0 mousetrap'
        placeholder='発見点を入力'
        autofocus
        v-model="memo"
        @input="updateInputMemoStartSeconds"
      />
      <div class="notice">
        <fa icon='question-circle' />
        発見点を「メモ：補助メモ」というフォーマットで記載すると補助メモを記載できます</div>
    </div>
    <div class='mt-3 d-flex gap-2'>
      <Button @click="addInsight">
        <fa icon='plus' />
        追加
        <small>(Ctrl + &#x23CE;)</small>
      </Button>
      <Button @click="reverseInsight">
        <fa icon='sort' />
        逆順
      </Button>
      <div class='input-group ml-2 w-25'>
        <div class='input-group-prepend'>
          <div class='input-group-text rounded-1'>
            <fa icon='history' />
            ラグ
          </div>
        </div>
        <input
          class='form-control mousetrap'
          placeholder='10'
          type='number'
          min=0
          v-model="insightOffsetSeconds"
        >
        <div class='input-group-append'>
          <div class='input-group-text rounded-0'>秒</div>
        </div>
      </div>
      <div style='margin-left:auto;'>
        <Button
          data-bs-toggle='modal'
          data-bs-target='#inputCsvModal'
          :disabled="checkPopoverEnabled(insights)"
        >
          <fa icon='upload' />
          データの取り込み
        </Button>
      </div>
      <InputCsvModal
        @AddInsightsFromFile='checkInsightsExists($event)'
        tabindex='-1'
      />
      <InputCsvConfirmModal
        @addCsvInsights='addCsvInsights'
        tabindex='-1'
      />
    </div>
</template>

<script>
  import Button from '../components/Button.vue';
  import GlobalMethods from '../mixins/globalMethods.js';
  import InputCsvModal from '../components/InputCsvModal.vue';
  import InputCsvConfirmModal from '../components/InputCsvConfirmModal.vue';

  export default {
    name: 'InsightControllArea',
    mixins: [GlobalMethods],
    props: {
      insights: Array,
      currentSeconds: {
        type: Number,
        required: true,
        default: 0,
      }
    },
    emits: ['addInsight', 'updateStartInputMemoSeconds'],
    data() {
      return {
        memo: '',
        insightOffsetSeconds: 5,
        inputMemoStartSeconds: undefined,
        InputCsvInsights: [],
      }
    },
    components: {
      Button,
      InputCsvModal,
      InputCsvConfirmModal,
    },
    computed: {
      localInsights: {
        get: function() {
          return this.insights;
        },
        set: function(insight) {
          this.$emit('addInsight', insight);
        },
      },
    },
    watch: {
      localInsights: {
        handler: function(){
          this.storeInsightsToLocalStorage();
        },
        deep: true
      }
    },
    mounted() {
      const self = this;
      // ショートカットキーの設定
      self.$mousetrap.bind('mod+enter', function() {
        self.addInsight();
        return false;
      });
    },
    methods: {
      storeInsightsToLocalStorage: function() {
        localStorage.setItem('poplayerInsights', JSON.stringify(this.localInsights));
      },
      checkInsightsExists: function(e) {
        if (this.localInsights.length) {
          this.InputCsvInsights.splice(0);
          this.InputCsvInsights.push(...e);
          global.$('#inputCsvConfirmModal').modal('show');
          return;
        }
        this.localInsights.push(...e);
      },
      addCsvInsights: function() {
        this.localInsights.push(...this.InputCsvInsights);
        this.InputCsvInsights.splice(0);
      },
      addInsight: function() {
        const self = this;
        if (self.memo == '') { return false; }

        const memoArr = self.memo.split('：');
        const startSeconds = self.inputMemoStartSeconds - self.insightOffsetSeconds;
        const endSeconds = startSeconds + 10;

        self.localInsights.unshift({
          startTime: self.convertSecondToTime((startSeconds > 0) ? startSeconds : 0),
          endTime: self.convertSecondToTime(endSeconds),
          memo: memoArr[0],
          subMemo: function() {
            if (memoArr[1]) {
              return memoArr[1];
            } else {
              return '';
            }
          }(),
          isZmeeting: false,
        });
        self.memo = '';
        this.inputMemoStartSeconds = undefined;
      },
      reverseInsight: function() {
        const self = this;
        if (self.localInsights.length < 2) return;
        const array = Object.assign([], this.insights);
        const isDesc = self.convertTimeToSecond(array[0].startTime) > self.convertTimeToSecond(array.slice(-1)[0].startTime)
        self.localInsights = array.sort(function(a, b){
          if (isDesc){
            return (self.convertTimeToSecond(a.startTime) - self.convertTimeToSecond(b.startTime))
          } else {
            return (self.convertTimeToSecond(b.startTime) - self.convertTimeToSecond(a.startTime))
          }
        });
      },
      updateInputMemoStartSeconds: function(e) {
        const self = this;
        if (e.target.value == '') {
          self.inputMemoStartSeconds = undefined;
        } else if (self.inputMemoStartSeconds == undefined) {
          self.inputMemoStartSeconds = Math.floor(self.currentSeconds);
        }
        self.$emit('updateStartInputMemoSeconds', self.inputMemoStartSeconds)
      },
    }
  }
</script>

<style scoped>
  .notice {
    line-height: 24px;
    font-size: 12px;
    color: rgb(124, 124, 124);
  }
</style>
