<template>
  <button
    @click="onClick"
    type='button'
    class='btn rounded-1'
    :class='`btn-${buttonStyle}`'
    :style="width ? `width: ${width}` : ''"
  >
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
  export default {
    props: {
      buttonStyle: {
        type: String,
        required: false,
        default: 'primary'
      },
      width: {
        type: String,
        required: false
      }
    }
  };
</script>
