<template>
  <div id='player' class='row m-1'>
  <TitleBar title='1. 発見点の追加' />
    <div
      class='left-section'
      v-bind:style="{
        width: formInputedVideoWidth < 170 ? 'calc(' + formInputedVideoWidth / 2 + 'vw - 30px)' : 'calc(100vw - 40px)'
      }"
    >
      <MonitorVideo
        :videoURL="playVideoUrl"
        :videoWidth="formInputedVideoWidth"
        :currentVideoSpeed="currentVideoSpeed"
        @videoTimeUpdate="setCurrentSeconds($event)"
        @videoSpeedChange="setCurrentVideoSpeed($event)"
      />
      <div class='d-flex gap-3'>
        <InputVideo
          @changeVideo="setFormInputedVideoUrl"
          v-show="showInputVideoForm"
        />
        <InputVideoWidth
          @changeVideoWidth="setFormInputedVideoWidth"
        />
      </div>
      <div>
        <KeyControllGuideForPlayer />
      </div>
    </div>
    <div
      class='right-section'
      v-bind:style="{
        width: formInputedVideoWidth < 170 ? 'calc(' +  (100 - formInputedVideoWidth / 2)  + 'vw - 30px)' : 'calc(50vw - 20px)',
      }"
    >
      <AddInsights
        @jumpTo="setCurrentSeconds($event, true)"
        :currentSeconds="currentSeconds"
        :currentVideoSpeed="currentVideoSpeed"
      />
    </div>
  </div>
</template>

<script>
  import TitleBar from '../components/TitleBar.vue';
  import MonitorVideo from '../components/MonitorVideo.vue';
  import AddInsights from '../components/AddInsights.vue';
  import KeyControllGuideForPlayer from '../components/KeyControllGuideForPlayer.vue';
  import InputVideo from '../components/InputVideo.vue';
  import InputVideoWidth from '../components/InputVideoWidth.vue';

  export default {
    components: {
      TitleBar,
      MonitorVideo,
      AddInsights,
      KeyControllGuideForPlayer,
      InputVideo,
      InputVideoWidth,
    },
    data(){
      return {
        currentVideoSpeed: 1.0,
        currentSeconds: 0,
        formInputedVideoUrl: null,
        formInputedVideoWidth: 100,
      }
    },
    props: {
      showInputVideoForm: {
        type: Boolean,
        required: false,
        default: false,
        formInputedVideoUrl: null,
      },
      videoUrl: {
        type: String,
        required: false,
        default: null
      }
    },
    mounted() {
      this.video = document.querySelector('video');
    },
    computed: {
      playVideoUrl() {
        if (this.videoUrl) return this.videoUrl;
        return this.formInputedVideoUrl;
      }
    },
    methods: {
      setFormInputedVideoUrl: function(videoURL) {
        this.formInputedVideoUrl = videoURL;
      },
      setFormInputedVideoWidth: function(width) {
        this.formInputedVideoWidth = width;
      },
      setCurrentVideoSpeed: function(videoSpeed) {
        this.currentVideoSpeed = videoSpeed;
      },
      setCurrentSeconds: function(second, withPlay = false) {
        this.currentSeconds = second;
        if (withPlay) {
          this.video.currentTime = second;
          this.video.play()
        }
      },
    }
  };
</script>

<style scoped>
  .left-section {
    margin-bottom: 20px;
  }

  .right-section {
    max-width: calc(50vw - 20px);
  }
</style>
