<template>
  <div id='deleteInsightsModal' class='modal'>
    <div class='modal-dialog modal-dialog-centered'>
      <div class='modal-content'>
        <div class='modal-body'>
          <div class="d-flex flex-row-reverse">
            <button
              id="closeModalBtn"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="閉じる"
            />
          </div>
          <div class="d-flex justify-content-center m-1">
            POPlayerに入力中のデータを削除します。<br>
            よろしいですか？
          </div>
          <div class="d-flex justify-content-center m-4">
            <Button
              class="mx-2"
              :width="'150px'"
              data-bs-dismiss="modal">
              キャンセル
            </Button>
            <Button
              class="mx-2"
              data-bs-dismiss="modal"
              :buttonStyle="'danger'"
              :width="'150px'"
              @click="$emit('deleteInsights')">
              削除する
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../components/Button.vue';

  export default {
    emits: ['deleteInsights'],
    components: {
      Button
    }
  }
</script>
