import { createWebHistory, createRouter } from "vue-router";
import Player from "@/views/Player.vue";
import SettingTime from "@/views/SettingTime.vue";

const routes = [
  {
    path: "/",
    name: "Player",
    components: {
      default: Player,
    },
    meta: { title: 'Poplayer | 発見点の追加' }
  },
  {
    path: "/setting-time",
    name: "SettingTime",
    component: SettingTime,
    meta: { title: 'Poplayer | 時間の設定' },
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

export default router;
