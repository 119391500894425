<template>
  <div class='d-flex gap-3 align-items-start'>
    <Button
      @click="generateOutputData"
      :disabled="checkPopoverEnabled(insights)"
    >
      時間リストを生成
    </Button>
    <textarea
      id='outputData'
      class='form-control rounded-0 mousetrap mb-2'
      placeholder='開始時間・終了時間リストが出力されます'
      :value="outputData"
      readonly
    />
  </div>
</template>

<script>
  import Button from '../components/Button.vue';
  import GlobalMethods from '../mixins/globalMethods.js';

  export default {
    mixins: [ GlobalMethods ],
    components: {
      Button,
    },
    props: {
      insights: Array,
    },
    data() {
      return {
        outputData: '',
      }
    },
    methods: {
      generateOutputData: function() {
        var self = this;
        var errFlg = false;
        self.insights.forEach(insight => {
          if (!insight.startTime) {
            errFlg = true;
          }
          var endTime = insight.endTime == undefined ? "\n" : "\t" + insight.endTime + "\n"
          self.outputData += insight.startTime + endTime;
        });
        if (errFlg) {
          alert('時間が入力されていない箇所があります');
          self.outputData = '';
        }
      }
    }
  }
</script>

<style scoped>
  textarea {
    font-size: 14px;
  }
  button {
    width: 220px;
  }
</style>
