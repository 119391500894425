<template>
  <Popper arrow :show="show">
    <slot />
  </Popper>
</template>

<script>
  import { ref } from "vue";
  import Popper from "vue3-popper";

  export default {
    components: {
      Popper,
    },
    props: {
      type: {
        type: String,
        required: false,
        default: 'info',
      },
      show: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    setup(props) {
      let backgroundColor = '';
      if (!props.type || props.type === 'info') backgroundColor = ref('#fff');
      else if (props.type === 'error') backgroundColor = ref('#dc3545');

      let color = '';
      if (!props.type || props.type === 'info') color = ref('#212529');
      if (props.type === 'error') color = ref('#fff');

      return { backgroundColor, color };
    },
  }
</script>

<style scoped>
  :deep(div.popper) {
    background: v-bind(backgroundColor);
    padding: 0.5rem 0.75rem;
    border-radius: 0.3rem;
    color: v-bind(color);
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  :deep(div.popper #arrow::before) {
    background: v-bind(backgroundColor);
  }

  :deep(div.popper:hover),
  :deep(div.popper:hover > #arrow::before) {
    background: v-bind(backgroundColor);
  }
</style>
