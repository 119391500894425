<template>
  <textarea
    id='insightText'
    class='form-control rounded-0 mousetrap mb-2'
    placeholder='発見点エクセルから「発見点」列と「詳細」列を貼り付け'
    :value="inputText"
    @input="$emit('update:inputText', $event.target.value)"
  />
</template>

<script>
  export default {
    props: {
      inputText: {
        type: String,
        required: false,
        default: () => {
          return '';
        },
      },
    },
    emits: ['update:inputText']
  }
</script>

<style scoped>
  textarea {
    font-size: 14px;
    min-height: 200px;
  }
</style>
